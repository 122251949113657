import { useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter } from "react-router-dom";
import loadable from "@loadable/component";

import ErrorBoundary from "./components/ErrorBoundary";
import Loading from "./components/Loading";
import { useErrorHandler } from "./hooks/error";
import { cleanErrors } from "./redux/errorSlice";
import { useAppDispatch, useAppSelector } from "./redux/hooks";

const RootNavigation = loadable(() => import("./navigation"));

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector(state => state.error);

  useErrorHandler(error, () => dispatch(cleanErrors()));

  useEffect(() => {
    ReactGA.initialize(
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_TEST_ID ?? "",
    );
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <RootNavigation fallback={<Loading />} />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
