import { CSSProperties, useEffect } from "react";
import { Spin } from "antd";

import { getClassNames } from "../../helpers/format";
import { ILoadingComponentProps } from "./index.interfaces";

import { ReactComponent as LogoBrand } from "../../assets/svg/logo-brand.svg";
import styles from "./index.module.css";

const Loading: React.FC<ILoadingComponentProps> = props => {
  const { opacity = 0.7, blur = 30, className } = props;
  const cssProperties: CSSProperties = {
    background: `rgba(255, 255, 255, ${opacity})`,
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div
      className={getClassNames(styles.container, className)}
      style={cssProperties}
    >
      <LogoBrand className={styles.logo} />
      <Spin size="large" />
    </div>
  );
};

export default Loading;
