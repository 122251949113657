import {
  LOGIN_TO_REDIRECT_URL,
  PRODUCTS_ON_CART,
  REGISTRATION_STEP_GUID,
  USER_ACCESS_TOKEN,
  USER_REFRESH_TOKEN,
} from "../constants/storage";
import { IGuid } from "../global/index.interfaces";

// Access Token
export const getUserAccessToken = () => {
  return localStorage.getItem(USER_ACCESS_TOKEN) || "";
};

export const setUserAccessToken = (token: string) => {
  localStorage.setItem(USER_ACCESS_TOKEN, token);
};

export const removeUserAccessToken = () => {
  localStorage.removeItem(USER_ACCESS_TOKEN);
};

// Refresh Token
export const getUserRefreshToken = () => {
  return localStorage.getItem(USER_REFRESH_TOKEN) || "";
};

export const setUserRefreshToken = (token: string) => {
  localStorage.setItem(USER_REFRESH_TOKEN, token);
};

export const removeUserRefreshToken = () => {
  localStorage.removeItem(USER_REFRESH_TOKEN);
};

// Registration steps
export const getRegistrationStepGuid = (): IGuid | null => {
  const guid = sessionStorage.getItem(REGISTRATION_STEP_GUID);
  if (guid) {
    return JSON.parse(guid) as IGuid;
  }

  return null;
};

export const setRegistrationStepGuid = (guid: IGuid) => {
  sessionStorage.setItem(REGISTRATION_STEP_GUID, JSON.stringify(guid));
};

export const removeRegistrationStepGuid = () => {
  sessionStorage.removeItem(REGISTRATION_STEP_GUID);
};

// Redirection URL
export const getLoginToRedirectUrl = () => {
  return sessionStorage.getItem(LOGIN_TO_REDIRECT_URL);
};

export const setLoginToRedirectUrl = (path: string) => {
  sessionStorage.setItem(LOGIN_TO_REDIRECT_URL, path);
};

export const removeLoginToRedirectUrl = () => {
  sessionStorage.removeItem(LOGIN_TO_REDIRECT_URL);
};

// Products on cart
export const getCartProductsOnStorage = () => {
  const products = localStorage.getItem(PRODUCTS_ON_CART);

  if (!products) {
    return products;
  }

  return JSON.parse(products);
};

export const setCartProductsOnStorage = (productIds: number[]) => {
  localStorage.setItem(PRODUCTS_ON_CART, JSON.stringify(productIds));
};

export const removeCartProductsOnStorage = () => {
  localStorage.removeItem(PRODUCTS_ON_CART);
};

export const clearStorages = () => {
  localStorage.clear();
  sessionStorage.clear();
};
