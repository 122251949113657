import { AxiosError } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../util/api";
import { AppThunk } from "../store";
import { IClientLogPayload, IErrorState } from "./index.interfaces";

const initialState: IErrorState = {
  loading: false,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    errorFetchStarted: state => {
      state.loading = true;
      state.error = undefined;
    },
    errorFetchSuccess: state => {
      state.loading = false;
    },
    errorFetchFulfilled: state => {
      state.loading = false;
    },
    errorFetchFailed: (state, action: PayloadAction<AxiosError<string>>) => {
      state.loading = false;
      state.error = action.payload;
    },
    cleanErrors: state => {
      state.error = undefined;
    },
  },
});

export const {
  errorFetchStarted,
  errorFetchSuccess,
  errorFetchFulfilled,
  errorFetchFailed,
  cleanErrors,
} = errorSlice.actions;

export const saveClientError =
  (payload: IClientLogPayload): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(errorFetchStarted());

      await api.post("/Common/ClientLog", payload);

      dispatch(errorFetchSuccess());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));

      throw err;
    }
  };

export default errorSlice.reducer;
