import logger from "redux-logger";
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import addressReducer from "./addressSlice";
import authReducer from "./authSlice";
import commonReducer from "./commonSlice";
import contactUsReducer from "./contactUsSlice";
import errorReducer from "./errorSlice";
import licenseReducer from "./licenseSlice";
import notificationReducer from "./notificationSlice";
import productReducer from "./productSlice";
import publishProductReducer from "./publishProductSlice";
import recoverPasswordReducer from "./recoverPasswordSlice";
import registerReducer from "./registerSlice";
import storeImageReducer from "./storeImageSlice";
import storeReducer from "./storeSlice";
import subscriptionReducer from "./subscriptionSlice";
import userReducer from "./userSlice";

export const store = configureStore({
  reducer: {
    error: errorReducer,
    common: commonReducer,
    auth: authReducer,
    contactUs: contactUsReducer,
    recoverPassword: recoverPasswordReducer,
    register: registerReducer,
    product: productReducer,
    publishProduct: publishProductReducer,
    store: storeReducer,
    storeImage: storeImageReducer,
    user: userReducer,
    notification: notificationReducer,
    license: licenseReducer,
    address: addressReducer,
    subscription: subscriptionReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
