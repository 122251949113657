import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import { IProductState, IProductStaticData } from "./index.interfaces";

const initialState: IProductState = {
  loading: false,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchSucceed: state => {
      state.loading = false;
    },
    fetchProductStaticDataFulfilled: (
      state,
      action: PayloadAction<IProductStaticData>,
    ) => {
      state.loading = false;
      state.categories = action.payload.categories;
      state.strains = action.payload.strains;
    },
    fetchFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchStarted,
  fetchSucceed,
  fetchProductStaticDataFulfilled,
  fetchFailed,
} = productSlice.actions;

export const getProductStaticData =
  (): AppThunk<Promise<IProductStaticData>> => async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data } = await api.get<any, AxiosResponse<IProductStaticData>>(
        "/Common/ProductStaticData",
      );

      dispatch(fetchProductStaticDataFulfilled(data));

      return data;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const getProduct =
  (productId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());
      console.log(productId);

      // const { data: token }: any = await api.post("/identity/signIn", payload);

      // dispatch(signInFulfilled(token));

      // return token;
    } catch (e: any) {
      dispatch(fetchFailed(e.response));

      throw e.response;
    }
  };

export const makeProductAsDraft =
  (productId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.patch(`/Products/${productId}/MakeAsDraft`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const makeProductPublished =
  (productId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.patch(`/Products/${productId}/Publish`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default productSlice.reducer;
