import { Button as AntButton } from "antd";

import { getClassNames } from "../../helpers/format";
import { IButtonComponentProps } from "./index.interfaces";

import styles from "./index.module.css";

const Button: React.FC<IButtonComponentProps> = props => {
  const {
    buttonType = "primary",
    noStyle = false,
    block = false,
    children,
    ...rest
  } = props;
  const buttonProps = { ...rest };

  switch (buttonType) {
    case "primary": {
      buttonProps.type = "primary";
      buttonProps.className = styles.primary;
      break;
    }
    case "secondary": {
      buttonProps.type = "default";
      buttonProps.className = styles.secondary;
      break;
    }
    case "disabled": {
      buttonProps.type = "default";
      buttonProps.disabled = true;
      break;
    }
    case "outlined": {
      buttonProps.type = "default";
      buttonProps.className = styles.outlined;
      break;
    }
    case "link": {
      buttonProps.type = "text";
      buttonProps.className = styles.link;
      break;
    }
    case "text": {
      buttonProps.type = "link";
      buttonProps.className = styles.text;
      break;
    }
  }

  buttonProps.className = getClassNames(
    styles.button,
    noStyle && styles.noStyle,
    block && styles.block,
    buttonProps.className,
    rest.className,
  );

  return <AntButton {...buttonProps}>{children}</AntButton>;
};

export default Button;
