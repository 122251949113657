import i18n from "../i18n";

export const PUBLIC_PAGES = {
  LandingPage: "/",
  BusinessLandingPage: "/for-business",
  SignInPage: "/login",
  RegisterPage: "/register",
  EmailVerificationPage: "/email-verification",
  CreatePasswordPage: "/create-password",
  UpdatePasswordPage: "/update-password",
  ForgotPasswordPage: "/forgot-password",
  RetrievePasswordPage: "/retrieve-password",
  ContactUsPage: "/contact-us",
  ProductsPage: "/products",
  ProductPage: "/products/:productId",
  StoresPage: "/stores",
  StorePage: "/stores/:storeId",
  TermsAndConditionsPage: "/terms-and-conditions",
  PrivacyPolicyPage: "/privacy-policy",
  CookiesPolicyPage: "/cookies-policy",
  CartPage: "/cart",
};

export const PRIVATE_PAGES = {
  ConversationsPage: "/conversation",

  BusinessDashboardPage: "/business/dashboard",
  BusinessBrowseProductsPage: "/business/browse-products",
  BusinessMyStoresPage: "/business/my-stores",
  BusinessOrdersPage: "/business/orders",
  BusinessAdvertisingPage: "/business/advertising",

  BusinessSettingsPage: "/business/settings",
  // Those for general account
  BusinessSettingsAccountPage: "/business/settings/account",
  BusinessSettingsChangePasswordPage: "/business/settings/change-password",
  BusinessSettingsSubscriptionPage: "/business/settings/subscription",
  BusinessSettingsStoresPage: "/business/settings/stores",
  BusinessSettingsAdsPage: "/business/settings/ads",
  BusinessSettingsNotificationsPage: "/business/settings/notifications",
  // Those for store (business) account
  BusinessSettingsStorePage: "/business/settings/store",
  BusinessSettingsStoreShippingPage: "/business/settings/store/shipping",
  BusinessSettingsStoreNotificationsPage:
    "/business/settings/store/notifications",
  BusinessSettingsStorePromoCodesPage: "/business/settings/store/promo-codes",
  BusinessSettingsStoreAddressesPage: "/business/settings/store/addresses",
  BusinessSettingsStoreLicensesPage: "/business/settings/store/licenses",
  // Publish products
  PublishProductGeneralInfoPage:
    "/business/stores/:storeId/products/publish/general",
  PublishProductCertificateOfAnalysisPage:
    "/business/stores/:storeId/products/publish/cof",
  PublishProductSalesInfoPage:
    "/business/stores/:storeId/products/publish/sales",
  PublishProductShippingInfoPage:
    "/business/stores/:storeId/products/publish/shipping",
  BusinessWishlistPage: "/business/wishlist",
  BusinessPurchasesPage: "/business/purchases",

  ConsumerOrdersPage: "/consumer/orders",
  ConsumerBrowseProductsPage: "/consumer/browse-products",
  ConsumerWishlistPage: "/consumer/wishlist",
  ConsumerPurchasesPage: "/consumer/purchases",
  ConsumerDeliveryAddressPage: "/consumer/delivery-address",
  ConsumerMedicalIDPage: "/consumer/medical-id",

  ConsumerSettingsPage: "/consumer/settings",
  ConsumerSettingsAccountPage: "/consumer/settings/account",
  ConsumerSettingsChangePasswordPage: "/consumer/settings/change-password",
};

export const PAGE_NAMES: Record<string, string> = {
  [PUBLIC_PAGES.LandingPage]: i18n.t("page_names.landing"),
  [PUBLIC_PAGES.BusinessLandingPage]: i18n.t("page_names.for_business"),
  [PUBLIC_PAGES.SignInPage]: i18n.t("page_names.login"),
  [PUBLIC_PAGES.RegisterPage]: i18n.t("page_names.register"),
  [PUBLIC_PAGES.EmailVerificationPage]: i18n.t("page_names.email_verification"),
  [PUBLIC_PAGES.CreatePasswordPage]: i18n.t("page_names.create_password"),
  [PUBLIC_PAGES.UpdatePasswordPage]: i18n.t("page_names.create_new_password"),
  [PUBLIC_PAGES.ForgotPasswordPage]: i18n.t("page_names.forgot_password"),
  [PUBLIC_PAGES.RetrievePasswordPage]: i18n.t("page_names.retrieve_password"),
  [PUBLIC_PAGES.ContactUsPage]: i18n.t("page_names.contact_us"),
  [PUBLIC_PAGES.ProductsPage]: i18n.t("page_names.products"),
  [PUBLIC_PAGES.ProductPage]: i18n.t("page_names.product"),
  [PUBLIC_PAGES.TermsAndConditionsPage]: i18n.t("page_names.product"),
  [PUBLIC_PAGES.ProductPage]: i18n.t("page_names.product"),
  [PUBLIC_PAGES.TermsAndConditionsPage]: i18n.t(
    "page_names.terms_and_conditions",
  ),
  [PUBLIC_PAGES.CartPage]: i18n.t("page_names.shopping_bag"),

  [PUBLIC_PAGES.PrivacyPolicyPage]: i18n.t("page_names.privacy_policy"),
  [PUBLIC_PAGES.CookiesPolicyPage]: i18n.t("page_names.cookies_policy"),
  [PRIVATE_PAGES.BusinessAdvertisingPage]: i18n.t("page_names.advertising"),
  [PRIVATE_PAGES.BusinessBrowseProductsPage]: i18n.t(
    "page_names.browse_products",
  ),
  [PRIVATE_PAGES.ConversationsPage]: i18n.t("page_names.conversations"),
  [PRIVATE_PAGES.BusinessDashboardPage]: i18n.t("page_names.dashboard"),
  [PRIVATE_PAGES.BusinessMyStoresPage]: i18n.t("page_names.my_stores"),
  [PRIVATE_PAGES.BusinessOrdersPage]: i18n.t("page_names.orders"),

  [PRIVATE_PAGES.ConsumerWishlistPage]: i18n.t("page_names.wishlist"),
};
