import { isEmpty, isNull, isUndefined } from "lodash";

// eslint-disable-next-line
window.noop = function () {};

window.isNullOrUndefined = function (value: any) {
  return isNull(value) || isUndefined(value);
};

window.isNullOrUndefinedOrEmpty = function (value: any) {
  return isNullOrUndefined(value) || isEmpty(value);
};

window.toNumber = function (value?: string | null) {
  if (isNullOrUndefinedOrEmpty(value)) {
    return 0;
  }

  return parseInt(value as string);
};

window.formSerializer = function (
  value: any,
  formData = new FormData(),
  parentKey = "",
): FormData {
  if (value instanceof Array) {
    value.forEach((x, index) => {
      formSerializer(x, formData, `${parentKey}[${index}]`);
    });
  } else if (value instanceof Object && !(value instanceof File)) {
    for (const key in value) {
      formSerializer(
        value[key],
        formData,
        parentKey + (parentKey ? `.${key}` : key),
      );
    }
  } else {
    formData.append(parentKey, value);
  }

  return formData;
};

export {};
