import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import {
  IAddressState,
  IAddStoreAddressPayload,
  IStoreAddress,
  IUpdateStoreAddressPayload,
} from "./index.interfaces";

const initialState: IAddressState = {
  loading: false,
};

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchSucceed: state => {
      state.loading = false;
    },
    fetchStoreAddressFulfilled: (
      state,
      action: PayloadAction<IStoreAddress>,
    ) => {
      state.loading = false;
      state.storeAddress = action.payload;
    },
    fetchStoreAddressesFulfilled: (
      state,
      action: PayloadAction<IStoreAddress[]>,
    ) => {
      state.loading = false;
      state.storeAddresses = action.payload;
    },
    fetchFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchStarted,
  fetchSucceed,
  fetchStoreAddressFulfilled,
  fetchStoreAddressesFulfilled,
  fetchFailed,
} = addressSlice.actions;

export const getStoreAddress =
  (
    storeId: number,
    addressId: number,
  ): AppThunk<Promise<IStoreAddress | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: storeAddress } = await api.get<
        any,
        AxiosResponse<IStoreAddress>
      >(`/Stores/${storeId}/Addresses/${addressId}`);

      dispatch(fetchStoreAddressFulfilled(storeAddress));

      return storeAddress;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const getStoreAddresses =
  (storeId: number): AppThunk<Promise<IStoreAddress[] | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: storeAddresses } = await api.get<
        any,
        AxiosResponse<IStoreAddress[]>
      >(`/Stores/${storeId}/Addresses`);

      dispatch(fetchStoreAddressesFulfilled(storeAddresses));

      return storeAddresses;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const addStoreAddress =
  (
    storeId: number,
    payload: IAddStoreAddressPayload,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Stores/${storeId}/Addresses/Add`, payload);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const updateStoreAddress =
  (
    storeId: number,
    addressId: number,
    payload: IUpdateStoreAddressPayload,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(
        `/Stores/${storeId}/Addresses/${addressId}/Update`,
        payload,
      );

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const deleteStoreAddress =
  (storeId: number, addressId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Stores/${storeId}/Addresses/${addressId}/Delete`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default addressSlice.reducer;
