import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import {
  IUpdateUserPasswordPayload,
  IUpdateUserPersonalInformationPayload,
  IUserResponse,
  IUserState,
} from "./index.interfaces";

const initialState: IUserState = {
  loading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchSucceed: state => {
      state.loading = false;
    },
    fetchFulfilled: (state, action: PayloadAction<IUserResponse>) => {
      state.loading = false;
      state.user = action.payload;
    },
    fetchFailed: (state, action: PayloadAction<AxiosError<string>>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchStarted, fetchSucceed, fetchFulfilled, fetchFailed } =
  userSlice.actions;

export const getUser =
  (): AppThunk<Promise<IUserResponse | undefined>> => async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: user } = await api.get<any, AxiosResponse<IUserResponse>>(
        "/User",
      );

      dispatch(fetchFulfilled(user));

      return user;
    } catch (e) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const updateUserPersonalInformation =
  (payload: IUpdateUserPersonalInformationPayload): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.postForm(
        "/User/UpdatePersonalInformation",
        formSerializer(payload),
      );

      dispatch(fetchSucceed());
    } catch (e) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const removeUserAvatar =
  (): AppThunk<Promise<void>> => async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post("/User/RemoveAvatar");

      dispatch(fetchSucceed());
    } catch (e) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const updateUserPassword =
  (payload: IUpdateUserPasswordPayload): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post("/User/UpdatePassword", payload);

      dispatch(fetchSucceed());
    } catch (e) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default userSlice.reducer;
