import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IEmailCodeVerification, IGuid } from "../../global/index.interfaces";
import {
  clearStorages,
  getRegistrationStepGuid,
  setRegistrationStepGuid,
} from "../../helpers/storage";
import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import {
  ICreatePasswordPayload,
  IPreRegisterUserPayload,
  IRegisterState,
} from "./index.interfaces";

const initialState: IRegisterState = {
  loading: false,
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchFulfilled: state => {
      state.loading = false;
    },
    fetchFailed: (state, action: PayloadAction<AxiosError<string>>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchStarted, fetchFulfilled, fetchFailed } =
  registerSlice.actions;

export const preRegisterUser =
  (payload: IPreRegisterUserPayload): AppThunk<Promise<IGuid | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data } = await api.post<any, AxiosResponse<IGuid>>(
        "/Register/PreRegisterUser",
        payload,
      );

      setRegistrationStepGuid(data);

      dispatch(fetchFulfilled());

      return data;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const verifyUserEmail =
  (payload: IEmailCodeVerification): AppThunk<Promise<IGuid | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());
      const guid = getRegistrationStepGuid();

      const { data } = await api.post<any, AxiosResponse<IGuid>>(
        "/Register/VerifyEmail",
        payload,
        {
          headers: {
            Key: guid?.key,
          },
        },
      );

      setRegistrationStepGuid(data);

      dispatch(fetchFulfilled());

      return data;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const createUserPassword =
  (payload: ICreatePasswordPayload): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());
      const guid = getRegistrationStepGuid();

      await api.post("/Register/CreatePassword", payload, {
        headers: {
          Key: guid?.key,
        },
      });

      clearStorages();

      dispatch(fetchFulfilled());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const resendEmailValidationCode =
  (): AppThunk<Promise<IGuid | undefined>> => async dispatch => {
    try {
      dispatch(fetchStarted());
      const guid = getRegistrationStepGuid();

      const { data } = await api.post<any, AxiosResponse<IGuid>>(
        "/Register/ResendEmailValidationCode",
        {},
        {
          headers: {
            Key: guid?.key,
          },
        },
      );

      setRegistrationStepGuid(data);

      dispatch(fetchFulfilled());

      return data;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default registerSlice.reducer;
