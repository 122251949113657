import { AxiosError } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import { IContactUsPayload, IContactUsState } from "./index.interfaces";

const initialState: IContactUsState = {
  loading: false,
};

export const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchFulfilled: state => {
      state.loading = false;
    },
    fetchFailed: (state, action: PayloadAction<AxiosError<string>>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchStarted, fetchFulfilled, fetchFailed } =
  contactUsSlice.actions;

export const sendContactUsEmail =
  (payload: IContactUsPayload): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post("/Public/SendContactUsEmail", payload);

      dispatch(fetchFulfilled());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default contactUsSlice.reducer;
