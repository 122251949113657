import { t } from "i18next";

export const PROMO_CODE_STATUS_TYPES = {
  None: 1,
  Applied: 2,
  Invalid: 3,
  Expired: 4,
} as const;

export const PROMO_CODE_STATUSES = [
  {
    label: t("common.none"),
    value: PROMO_CODE_STATUS_TYPES.None,
  },
  {
    label: t("common.applied"),
    value: PROMO_CODE_STATUS_TYPES.Applied,
  },
  {
    label: t("common.invalid"),
    value: PROMO_CODE_STATUS_TYPES.Invalid,
  },
  {
    label: t("common.expired"),
    value: PROMO_CODE_STATUS_TYPES.Expired,
  },
];
