import { Component, ErrorInfo } from "react";
import { Result } from "antd";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { saveClientError } from "../../redux/errorSlice";
import { IClientLogPayload } from "../../redux/errorSlice/index.interfaces";
import { AppDispatch, RootState } from "../../redux/store";
import Button from "../Button";
import { IErrorBoundaryProps, IErrorBoundaryState } from "./index.interfaces";

import { ReactComponent as Warning } from "../../assets/svg/results/warning.svg";
import styles from "./index.module.css";

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.saveClientError({
      path: window.location.href,
      message: error.message,
      stack: error.stack || errorInfo.componentStack,
    });
  }

  render() {
    const { t, children } = this.props;

    if (this.state.hasError) {
      // Can render any custom fallback UI
      return (
        <div className={styles.container}>
          <Result
            title={t("error_page.title")}
            icon={<Warning className={styles.svg} />}
            subTitle={t("error_page.text")}
            extra={
              <Button
                buttonType="primary"
                onClick={() => {
                  this.setState({ hasError: false });

                  window.location.reload();
                }}
              >
                {t("error_page.reload")}
              </Button>
            }
            className={styles.result}
          />
        </div>
      );
    }

    return children;
  }
}

const mapStateToProps = (state: RootState) => {
  const { error } = state;

  return {
    loading: error.loading,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    saveClientError: (payload: IClientLogPayload) =>
      dispatch(saveClientError(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ErrorBoundary));
