import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import {
  ICreateStorePayload,
  IStore,
  IStoreSettings,
  IStoresSettings,
  IStoreState,
  IUpdateStorePayload,
  IUpdateStoreSettingsPayload,
} from "./index.interfaces";

const initialState: IStoreState = {
  loading: false,
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchSucceed: state => {
      state.loading = false;
    },
    fetchStoreFulfilled: (state, action: PayloadAction<IStore>) => {
      state.loading = false;
      state.store = action.payload;
    },
    fetchStoreSettingsFulfilled: (
      state,
      action: PayloadAction<IStoreSettings>,
    ) => {
      state.loading = false;
      state.storeSettings = action.payload;
    },
    fetchStoresSettingsFulfilled: (
      state,
      action: PayloadAction<IStoresSettings[]>,
    ) => {
      state.loading = false;
      state.storesSettings = action.payload;
    },
    fetchFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchStarted,
  fetchSucceed,
  fetchStoreFulfilled,
  fetchStoreSettingsFulfilled,
  fetchStoresSettingsFulfilled,
  fetchFailed,
} = storeSlice.actions;

export const getStoreSettings =
  (storeId: number): AppThunk<Promise<IStoreSettings | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: settingsStore } = await api.get<
        any,
        AxiosResponse<IStoreSettings>
      >(`/Stores/${storeId}/ForSettings`);

      dispatch(fetchStoreSettingsFulfilled(settingsStore));

      return settingsStore;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const getStoresSettings =
  (): AppThunk<Promise<IStoresSettings[] | undefined>> => async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: storesSettings } = await api.get<
        any,
        AxiosResponse<IStoresSettings[]>
      >(`/Stores/ForSettings`);

      dispatch(fetchStoresSettingsFulfilled(storesSettings));

      return storesSettings;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const updateStoreSettings =
  (
    storeId: number,
    payload: IUpdateStoreSettingsPayload,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Stores/${storeId}/UpdateSettings`, payload);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const createStore =
  (payload: ICreateStorePayload): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.postForm("/Stores/Create", formSerializer(payload));

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const getStore =
  (storeId: number): AppThunk<Promise<any | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data } = await api.get<any, AxiosResponse<IStore>>(
        `/Stores/${storeId}/ForPreview`,
      );

      dispatch(fetchStoreFulfilled(data));

      return data;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const updateStore =
  (storeId: number, payload: IUpdateStorePayload): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Stores/${storeId}/UpdateForPreview`, payload);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const deleteStore =
  (storeId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Stores/${storeId}/Delete`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default storeSlice.reducer;
