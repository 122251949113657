import { PRIVATE_PAGES, PUBLIC_PAGES } from "../constants/routes";
import { USER_ROLE } from "../constants/user";
import { UserRoleType } from "../global/index.types";
import { getLoginToRedirectUrl, removeLoginToRedirectUrl } from "./storage";

export const openInNewTab = (url?: string | URL) => {
  if (url) {
    window.open(url, "_blank", "noopener,noreferrer");
  }
};

export const isDevelopmentEnv = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
};

export const setLayoutScrollState = (overflow: "hidden" | "unset") => {
  document.body.style.overflow = overflow;
};

export const getOnLoadURL = (roleId?: UserRoleType) => {
  const redirectUrl = getLoginToRedirectUrl();
  removeLoginToRedirectUrl();

  if (redirectUrl) return redirectUrl;

  switch (roleId) {
    case USER_ROLE.Business:
      return PRIVATE_PAGES.BusinessDashboardPage;
    case USER_ROLE.Consumer:
      return PRIVATE_PAGES.ConsumerBrowseProductsPage;
    default:
      return PUBLIC_PAGES.LandingPage;
  }
};
