import { useCallback, useEffect } from "react";
import { message, Modal } from "antd";
import { AxiosError } from "axios";
import { t } from "i18next";

import { TOAST_MESSAGE_SHOWING_DURATION_SECONDS } from "../constants/time";
import { setLayoutScrollState } from "../helpers";

const { error: modalError } = Modal;
let hasNetworkError = false;

export const useErrorHandler = (
  error?: AxiosError<string>,
  callback?: () => void,
) => {
  const onModalClose = useCallback(() => {
    setLayoutScrollState("unset");
    if (callback) callback();
  }, [callback]);

  useEffect(() => {
    if (error?.code === "ERR_NETWORK" && !hasNetworkError) {
      setLayoutScrollState("hidden");
      hasNetworkError = true;

      modalError({
        title: t("error_messages.network.title"),
        content: t("error_messages.network.description"),
        onOk() {
          hasNetworkError = false;
        },
        afterClose() {
          setLayoutScrollState("unset");
        },
      });
    }

    if (error?.response) {
      setLayoutScrollState("hidden");

      if (error.response?.status >= 600) {
        message.warning(
          error?.response?.data || t("error_messages.common").toString(),
          TOAST_MESSAGE_SHOWING_DURATION_SECONDS,
          onModalClose,
        );
      } else {
        message.error(
          t("error_messages.common").toString(),
          TOAST_MESSAGE_SHOWING_DURATION_SECONDS,
          onModalClose,
        );
      }
    }
  }, [error, onModalClose, callback]);
};
