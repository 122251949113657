import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import {
  IAddIncompletePublishedProductImage,
  IIncompletePublishedProduct,
  IIncompletePublishedProductImage,
  IPublishProductCOFPayload,
  IPublishProductGeneralInfoPayload,
  IPublishProductSalesInfoPayload,
  IPublishProductShippingInfoPayload,
  IPublishProductState,
} from "./index.interfaces";

const initialState: IPublishProductState = {
  loading: false,
};

export const publishProductSlice = createSlice({
  name: "publishProductSlice",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchSucceed: state => {
      state.loading = false;
    },
    fetchIncompleteProductImagesFulfilled: (
      state,
      action: PayloadAction<IIncompletePublishedProductImage[]>,
    ) => {
      state.loading = false;
      state.incompleteProductImages = action.payload;
    },
    fetchIncompleteProductFulfilled: (
      state,
      action: PayloadAction<IIncompletePublishedProduct>,
    ) => {
      state.loading = false;
      state.incompleteProduct = action.payload;
    },
    fetchFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchStarted,
  fetchSucceed,
  fetchIncompleteProductFulfilled,
  fetchIncompleteProductImagesFulfilled,
  fetchFailed,
} = publishProductSlice.actions;

export const getIncompletePublishedProduct =
  (storeId: number): AppThunk<Promise<IIncompletePublishedProduct>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data } = await api.get<
        any,
        AxiosResponse<IIncompletePublishedProduct>
      >(`/Products/Incomplete/${storeId}`);

      dispatch(fetchIncompleteProductFulfilled(data));

      return data;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const getIncompletePublishedProductImages =
  (storeId: number): AppThunk<Promise<IIncompletePublishedProductImage[]>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data } = await api.get<
        any,
        AxiosResponse<IIncompletePublishedProductImage[]>
      >(`/Products/Incomplete/${storeId}/Images`);

      dispatch(fetchIncompleteProductImagesFulfilled(data));

      return data;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const addIncompletePublishedProductImage =
  (
    storeId: number,
    payload: IAddIncompletePublishedProductImage,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.postForm(
        `/Products/Incomplete/${storeId}/Image`,
        formSerializer(payload),
      );

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const chooseDefaultIncompletePublishedProductImage =
  (storeId: number, imageId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      // Choose default image
      await api.put(`/Products/Incomplete/${storeId}/Images/${imageId}`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const deleteIncompletePublishedProductImage =
  (storeId: number, imageId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.delete(`/Products/Incomplete/${storeId}/Images/${imageId}`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const createIncompleteProductAndAddGeneralInfo =
  (
    storeId: number,
    payload: IPublishProductGeneralInfoPayload,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Products/Incomplete/${storeId}/General`, payload);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const updateIncompleteProductAnalysis =
  (
    storeId: number,
    payload: IPublishProductCOFPayload,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.putForm(`/Products/Incomplete/${storeId}/Analysis`, payload);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const updateIncompleteProductSalesInfo =
  (
    storeId: number,
    payload: IPublishProductSalesInfoPayload,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.put(`/Products/Incomplete/${storeId}/Sales`, payload);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const updateIncompleteProductShippingInfo =
  (
    storeId: number,
    payload: IPublishProductShippingInfoPayload,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.put(`/Products/Incomplete/${storeId}/Shipping`, payload);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default publishProductSlice.reducer;
