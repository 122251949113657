import { isEmpty } from "lodash";

import { FileType } from "../global/index.types";

export const getClassNames = (
  ...args: Array<string | boolean | undefined>
): string => {
  const filteredArgs = args.filter(arg => !!arg);
  const classNames = filteredArgs.join(" ");

  return classNames;
};

export const getBearerToken = (token: string): string => {
  return `Bearer ${token}`;
};

export const getLimitedText = (text = "", charactersCount: number): string => {
  if (text.length <= charactersCount) {
    return text;
  }

  const limitedText = text.slice(0, charactersCount);

  return `${limitedText}...`;
};

export const getPriceAndCurrency = (
  price: number,
  prefix = "",
  suffix = "",
): string => {
  return `${prefix}${price} ${suffix}`;
};

export const getUrlWithParam = (url: string, param = ""): string => {
  const paramStartIndex = url.indexOf(":");

  if (paramStartIndex === -1 || !param) {
    return url;
  }

  const paramEndIndex = url.indexOf("/", paramStartIndex);
  const searchValue = url.substring(
    paramStartIndex,
    paramEndIndex !== -1 ? paramEndIndex : url.length,
  );
  const urlWithParam = url.replace(searchValue, param);

  return urlWithParam;
};

export const getUrlWithSearchParams = (
  url: string,
  searchParams?: string | URLSearchParams,
) => {
  if (searchParams) {
    return `${url}?${searchParams}`;
  }

  return url;
};

export const getFormattedOutOfMaxNumber = (
  max: number,
  value: number,
): string => {
  return value > max ? `${max}+` : value.toString();
};

export const getFormattedPhoneNumber = (
  countryCode: string,
  phoneNumber: string,
) => {
  const stateCodeLength = 3;
  const stateCode = phoneNumber.substring(0, stateCodeLength);
  const phone = phoneNumber.substring(stateCodeLength);

  return isEmpty(countryCode)
    ? `${stateCode} ${phone}`
    : `+${countryCode} ${stateCode} ${phone}`;
};

export const getPrefixedPhoneCode = (code: string) => {
  return `+${code}`;
};

export const getImageUrl = (imgSrc?: string) => {
  if (imgSrc) {
    return `${process.env.REACT_APP_API_URL}/${imgSrc}`;
  }
};

export const getFormattedUploadAcceptance = (...args: FileType[]) => {
  return args.join(", ");
};
