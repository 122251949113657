import axios, { AxiosError, AxiosResponse } from "axios";

import { PUBLIC_PAGES } from "../constants/routes";
import { getBearerToken } from "../helpers/format";
import {
  getUserAccessToken,
  getUserRefreshToken,
  removeUserAccessToken,
  removeUserRefreshToken,
  setUserAccessToken,
  setUserRefreshToken,
} from "../helpers/storage";
import { ISignInResponse } from "../redux/authSlice/index.interfaces";
import { startNotificationHub } from "../redux/notificationSlice";
import { store } from "../redux/store";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  formSerializer: {
    dots: true,
    indexes: null,
  },
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value: string | PromiseLike<string>) => void;
  reject: (reason?: any) => void;
}> = [];

const processQueue = (
  error: AxiosError<string> | null,
  accessToken?: string,
) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(accessToken || "");
    }
  });

  failedQueue = [];
};

api.interceptors.request.use(
  config => {
    const token = getUserAccessToken();
    if (token && config.headers) {
      config.headers.Authorization = getBearerToken(token);
    }

    return config;
  },
  error => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError<string>) => {
    const { config: prevRequest, response } = error;

    if (prevRequest && response && response.status === 401) {
      if (isRefreshing) {
        return new Promise<string>((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((accessToken: string) => {
            const headers = prevRequest.headers ?? {};
            headers.Authorization = getBearerToken(accessToken);

            return api({
              ...prevRequest,
              headers,
            });
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      return new Promise((resolve, reject) => {
        api
          .post<any, AxiosResponse<ISignInResponse>>("/Auth/RefreshToken", {
            accessToken: getUserAccessToken(),
            refreshToken: getUserRefreshToken(),
          })
          .then(({ data }) => {
            const headers = prevRequest.headers ?? {};
            headers.Authorization = getBearerToken(data.accessToken);

            processQueue(null, data.accessToken);

            setUserAccessToken(data.accessToken);
            setUserRefreshToken(data.refreshToken);
            store.dispatch(startNotificationHub());

            resolve(
              api({
                ...prevRequest,
                headers,
              }),
            );
          })
          .catch((err: AxiosError<string>) => {
            processQueue(err);

            if (err.code !== "ERR_NETWORK") {
              removeUserAccessToken();
              removeUserRefreshToken();
              window.location.href = PUBLIC_PAGES.SignInPage;
            }

            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

export default api;
