import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import {
  IStoreImage,
  IStoreImageState,
  IUploadStoreImagePayload,
} from "./index.interfaces";

const initialState: IStoreImageState = {
  loading: false,
};

export const storeImageSlice = createSlice({
  name: "storeImage",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchSucceed: state => {
      state.loading = false;
    },
    fetchProfileImagesFulfilled: (
      state,
      action: PayloadAction<IStoreImage[]>,
    ) => {
      state.loading = false;
      state.profileImages = action.payload;
    },
    fetchCoverImagesFulfilled: (
      state,
      action: PayloadAction<IStoreImage[]>,
    ) => {
      state.loading = false;
      state.coverImages = action.payload;
    },
    fetchFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchStarted,
  fetchSucceed,
  fetchCoverImagesFulfilled,
  fetchProfileImagesFulfilled,
  fetchFailed,
} = storeImageSlice.actions;

export const getStoreProfileImages =
  (storeId: number): AppThunk<Promise<IStoreImage[] | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: images } = await api.get<any, AxiosResponse<IStoreImage[]>>(
        `/Stores/${storeId}/ProfileImages`,
      );

      dispatch(fetchProfileImagesFulfilled(images));

      return images;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const uploadStoreProfileImage =
  (
    payload: IUploadStoreImagePayload,
    storeId: number,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.postForm(
        `/Stores/${storeId}/ProfileImages/Upload`,
        formSerializer(payload),
      );

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const chooseStoreProfileImage =
  (imageId: number, storeId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Stores/${storeId}/ProfileImages/${imageId}/Choose`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const deleteStoreProfileImage =
  (imageId: number, storeId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Stores/${storeId}/ProfileImages/${imageId}/Delete`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const getStoreCoverImages =
  (storeId: number): AppThunk<Promise<IStoreImage[] | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: images } = await api.get<any, AxiosResponse<IStoreImage[]>>(
        `/Stores/${storeId}/CoverImages`,
      );

      dispatch(fetchCoverImagesFulfilled(images));

      return images;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const uploadStoreCoverImage =
  (
    payload: IUploadStoreImagePayload,
    storeId: number,
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.postForm(
        `/Stores/${storeId}/CoverImages/Upload`,
        formSerializer(payload),
      );

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const chooseStoreCoverImage =
  (imageId: number, storeId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Stores/${storeId}/CoverImages/${imageId}/Choose`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const deleteStoreCoverImage =
  (imageId: number, storeId: number): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post(`/Stores/${storeId}/CoverImages/${imageId}/Delete`);

      dispatch(fetchSucceed());
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default storeImageSlice.reducer;
