import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  removeUserAccessToken,
  removeUserRefreshToken,
  setUserAccessToken,
  setUserRefreshToken,
} from "../../helpers/storage";
import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import {
  ISignInPayload,
  ISignInResponse,
  ISignInState,
} from "./index.interfaces";

const initialState: ISignInState = {
  loading: false,
};

export const signInSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchFulfilled: state => {
      state.loading = false;
    },
    fetchFailed: (state, action: PayloadAction<AxiosError<string>>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchStarted, fetchFulfilled, fetchFailed } =
  signInSlice.actions;

export const signIn =
  (payload: ISignInPayload): AppThunk<Promise<ISignInResponse | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data } = await api.post<any, AxiosResponse<ISignInResponse>>(
        "/Auth/SignIn",
        payload,
      );

      setUserAccessToken(data.accessToken);

      if (payload.remember) {
        setUserRefreshToken(data.refreshToken);
      }

      dispatch(fetchFulfilled());

      return data;
    } catch (e) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const logout =
  (url?: string): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      await api.post<any, AxiosResponse<ISignInResponse>>("/Auth/Logout");

      if (url) {
        window.location.href = url;
      }

      dispatch(fetchFulfilled());
    } catch (e) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    } finally {
      removeUserAccessToken();
      removeUserRefreshToken();
    }
  };

export default signInSlice.reducer;
