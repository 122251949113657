import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import {
  ICommonState,
  ICountry,
  ILicense,
  IPhoneCode,
  IState,
} from "./index.interfaces";

const initialState: ICommonState = {
  loading: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
      state.error = undefined;
    },
    fetchCountriesFulfilled: (state, action: PayloadAction<ICountry[]>) => {
      state.loading = false;
      state.countries = action.payload;
    },
    fetchStatesFulfilled: (state, action: PayloadAction<IState[]>) => {
      state.loading = false;
      state.states = action.payload;
    },
    fetchPhoneCodesFulfilled: (state, action: PayloadAction<IPhoneCode[]>) => {
      state.loading = false;
      state.phoneCodes = action.payload;
    },
    fetchLicensesFulfilled: (state, action: PayloadAction<ILicense[]>) => {
      state.loading = false;
      state.licenses = action.payload;
    },
    fetchFailed: (state, action: PayloadAction<AxiosError<string>>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchStarted,
  fetchCountriesFulfilled,
  fetchStatesFulfilled,
  fetchPhoneCodesFulfilled,
  fetchLicensesFulfilled,
  fetchFailed,
} = commonSlice.actions;

export const getCountries =
  (): AppThunk<Promise<ICountry[] | undefined>> => async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: countries } = await api.get<any, AxiosResponse<ICountry[]>>(
        "/Common/Countries",
      );

      dispatch(fetchCountriesFulfilled(countries));

      return countries;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const getStates =
  (countryId: number): AppThunk<Promise<IState[] | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: states } = await api.get<any, AxiosResponse<IState[]>>(
        `/Common/Countries/${countryId}/States`,
      );

      dispatch(fetchStatesFulfilled(states));

      return states;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const getPhoneCodes =
  (): AppThunk<Promise<IPhoneCode[] | undefined>> => async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: phoneCodes } = await api.get<
        any,
        AxiosResponse<IPhoneCode[]>
      >("/Common/PhoneCodes");

      dispatch(fetchPhoneCodesFulfilled(phoneCodes));

      return phoneCodes;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export const getLicenses =
  (stateId: number): AppThunk<Promise<ILicense[] | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data: licenses } = await api.get<any, AxiosResponse<ILicense[]>>(
        `/Common/States/${stateId}/Licenses`,
      );

      dispatch(fetchLicensesFulfilled(licenses));

      return licenses;
    } catch (e: any) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default commonSlice.reducer;
