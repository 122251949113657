import { AxiosError, AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PROMO_CODE_STATUS_TYPES } from "../../constants/promo";
import api from "../../util/api";
import { errorFetchFailed } from "../errorSlice";
import { AppThunk } from "../store";
import {
  ICreatePaymentIntentPayload,
  ICreatePaymentIntentResponse,
  ISubscriptionState,
} from "./index.interfaces";

const initialState: ISubscriptionState = {
  paymentIntent: {
    clientSecret: "",
    promoStatus: PROMO_CODE_STATUS_TYPES.None,
  },
  loading: false,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchSucceed: state => {
      state.loading = false;
    },
    fetchClientSecretFulfilled: (
      state,
      action: PayloadAction<ICreatePaymentIntentResponse>,
    ) => {
      state.loading = false;
      state.paymentIntent = action.payload;
    },
    fetchFailed: (state, action: PayloadAction<AxiosError<string>>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchStarted,
  fetchSucceed,
  fetchClientSecretFulfilled,
  fetchFailed,
} = subscriptionSlice.actions;

export const createPaymentIntent =
  (
    payload: ICreatePaymentIntentPayload,
  ): AppThunk<Promise<ICreatePaymentIntentResponse | undefined>> =>
  async dispatch => {
    try {
      dispatch(fetchStarted());

      const { data } = await api.post<
        any,
        AxiosResponse<ICreatePaymentIntentResponse>
      >("subscription/createPaymentIntent", payload);

      dispatch(fetchClientSecretFulfilled(data));

      return data;
    } catch (e) {
      const err = e as AxiosError<string>;
      dispatch(errorFetchFailed(err));
      dispatch(fetchFailed(err));

      throw err;
    }
  };

export default subscriptionSlice.reducer;
