import { AxiosError } from "axios";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NOTIFICATION_HUB_RECONNECTION_INTERVAL_MINUTES } from "../../constants/time";
import { isDevelopmentEnv } from "../../helpers";
import { getUserAccessToken } from "../../helpers/storage";
import { AppThunk } from "../store";
import { INotificationResponse, INotificationState } from "./index.interfaces";

const initialState: INotificationState = {
  loading: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchSucceed: state => {
      state.loading = false;
    },
    fetchFulfilled: (state, action: PayloadAction<INotificationResponse[]>) => {
      state.loading = false;
      state.notifications = action.payload;
    },
    fetchFailed: (
      state,
      action: PayloadAction<
        AxiosError<string> | signalR.HttpError | signalR.AbortError
      >,
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchStarted, fetchSucceed, fetchFulfilled, fetchFailed } =
  notificationSlice.actions;

export const startNotificationHub =
  (): AppThunk<Promise<void>> => async (dispatch, getStore) => {
    try {
      dispatch(fetchStarted());

      const accessToken = getUserAccessToken();
      if (accessToken) {
        const connection = new HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_API_URL}/notificationHub`, {
            accessTokenFactory: () => accessToken,
          })
          .configureLogging(
            isDevelopmentEnv() ? LogLevel.Information : LogLevel.None,
          )
          .withAutomaticReconnect()
          .build();

        connection.on(
          "ReceiveNotification",
          (notification: INotificationResponse) => {
            const notificationData =
              getStore().notification.notifications || [];
            const mergedNotifications = [notification, ...notificationData];
            dispatch(fetchFulfilled(mergedNotifications));
          },
        );

        await connection.start();

        connection.onclose(async () => {
          await connection.start();
        });
      }

      dispatch(fetchSucceed());
    } catch (e) {
      setTimeout(() => {
        dispatch(startNotificationHub());
      }, NOTIFICATION_HUB_RECONNECTION_INTERVAL_MINUTES);

      dispatch(fetchFailed(e));
      throw e;
    }
  };

export default notificationSlice.reducer;
